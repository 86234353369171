exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-templates-create-js": () => import("./../../../src/templates/create.js" /* webpackChunkName: "component---src-templates-create-js" */),
  "component---src-templates-free-invoice-template-js": () => import("./../../../src/templates/free-invoice-template.js" /* webpackChunkName: "component---src-templates-free-invoice-template-js" */),
  "component---src-templates-small-business-js": () => import("./../../../src/templates/small-business.js" /* webpackChunkName: "component---src-templates-small-business-js" */),
  "component---src-templates-software-js": () => import("./../../../src/templates/software.js" /* webpackChunkName: "component---src-templates-software-js" */),
  "component---src-templates-templates-js": () => import("./../../../src/templates/templates.js" /* webpackChunkName: "component---src-templates-templates-js" */)
}

