import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

export const AppContext = React.createContext(null);
const isBrowser = typeof window !== "undefined";
export const AppContextProvider = ({ children }) => {
  let eU;
  if (isBrowser) {
    eU = localStorage.getItem("user");
    if (eU) {
      eU = JSON.parse(eU);
    }
  }
  const [user, setUser] = useState(eU);

  useEffect(() => {
    // or with require() syntax:
    // const mixpanel = require('mixpanel-browser');

    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production
    mixpanel.init("2c699372d52051ce0721290d82e9c671", { debug: true });
  }, []);
  return (
    <AppContext.Provider
      value={{
        user,
        setUser: (u) => {
          if (!u) {
            localStorage.removeItem("user");
            setUser(null);
          } else setUser(u);
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
